<template>
  <main class="content">
    <div class="container">
      <div class="form-block">
        <header>
          <div class="logo alt">
            <img src="./../../assets/images/logo.png" alt="" />
          </div>
        </header>
        <div class="form">
          <p>Please provide new password below.</p>
          <ValidationObserver v-slot="{ invalid }">
            <form class="needs-validation" @submit.prevent="submit" novalidate>
              <div class="form-group">
                <ValidationProvider
                  name="New Password"
                  rules="required|confirmed:confirmation"
                  v-slot="v"
                >
                  <input
                    type="password"
                    class="form-control form-control-lg"
                    id="new-password-field"
                    placeholder="New Password"
                    v-model="new_password"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }"
                  />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="Password Confirmation"
                  rules="required"
                  v-slot="v"
                  vid="confirmation"
                >
                  <input
                    type="password"
                    class="form-control form-control-lg"
                    id="password-confirmation-field"
                    placeholder="Password Confirmation"
                    v-model="re_new_password"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }"
                  />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="invalid || loading"
                >
                  <span
                    v-show="loading"
                    class="spinner-grow spinner-grow-sm"
                    style="width: 1.0625rem; height: 1.0625rem"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Reset Password
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <footer>
          <div class="brand-logos d-flex">
            <div class="logo">
              <img src="./../../assets/images/bosch-logo.png" alt="" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/siemens-logo.png" alt="" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/neff.png" alt="" />
            </div>
          </div>
          <p>
             <a href="https://www.bosch-home.co.uk/store/terms-of-website-use" class="mr-3">Term of use </a><a href="https://www.bosch-home.co.uk/data-protection-information" class="mr-3">Data Protection Policy</a>
             <a target="_blank" href="https://www.bosch-home.co.uk/metapages/imprint/report-a-vulnerability#tab-navigation">Reporting vulnerabilities</a>
          </p>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "ForgetPasswordConfirm",
  components: {},
  props: {},
  data: function () {
    return {
      new_password: "",
      re_new_password: "",
      message: "",
      loading: false,
    };
  },
  methods: {
    submit: function () {
      this.loading = true;
      AuthService.resetPasswordConfirm(
        this.$route.params.uid,
        this.$route.params.token,
        this.new_password
      ).then(
        () => {
          this.$router.push("/login");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>